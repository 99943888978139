import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Unser Angebot</h1>
            <p className="font13">
              Wir unterteilen unser Angebot in drei Segmente um möglichst verschiedene Lösungen für verschiedene Anliegen bieten zu können 
              <br />
              Natürlich können auch individuelle Lösungen erarbeitet werden.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="CHF 15.90/Monat"
                title="Klein aber fein"
                text="Ideal für kleine Haushalte (1-2 Personen)"
                offers={[
                  { name: "1x Abholung/Monat", cheked: true },
                  { name: "1 Sack à 105 Liter / Abholung", cheked: true },
                  { name: "Jeden Monat Mail mit Statistiken", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="CHF 24.90/Monat"
                title="Die goldene Mitte"
                text="Geeignet für normal grosse Haushalte (4 Personen)"
                offers={[
                  { name: "2x Abholung/Monat", cheked: true },
                  { name: "1 Sack à 105 Liter / Abholung" , cheked: true },
                  { name: "Jeden Monat Mail mit Statistiken", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="CHF 29.90/Monat"
                title="B I G"
                text="MEHR MEHR MEHR!"
                offers={[
                  { name: "2 x Abholung/Monat", cheked: true },
                  { name: "2 Säcke à 105 Liter / Abholung ", cheked: true },
                  { name: "Jeden Monat Mail mit Statistiken", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




