import React from "react";
import styled from "styled-components";
// Assets
import TrashTruck from "../../assets/logos/trash-truck.png";
import TrennungIcon from "../../assets/logos/garbage.png";
import ReportingIcon from "../../assets/logos/tag.png";
import WiederIcon from "../../assets/logos/cycle.png";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = TrashTruck;
      break;
    case "monitor":
      getIcon = TrennungIcon;
      break;
    case "browser":
      getIcon = ReportingIcon;
      break;
    case "printer":
      getIcon = WiederIcon;
      break;
    default:
      getIcon = TrashTruck;
      break;
  }


  return (
    <React.Fragment>
    <img src={getIcon} width={100}/>
    <Wrapper className="flex flexColumn">
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;